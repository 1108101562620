import React from 'react';

const UseTermsLM = () => (
  <>
    <h3>Termos e condições gerais de uso do sistema Legal Metrics</h3>

    <p>
      Os serviços do software
      {' '}
      <b>Legal Metrics</b>
      {' '}
      são fornecidos pela
      {' '}
      <b>Juristec</b>
      {' '}
      Sistemas Jurídicos Integrados Ltda ("
      <b>Juristec</b>
      "), pessoa
      jurídica de direito privado, com nome fantasia, inscrita no CNPJ/MF sob o
      nº 27.434.472/0001-09, titular da propriedade intelectual sobre software, website,
      aplicativos, conteúdos e demais ativos relacionados à plataforma
      {' '}
      <b>Legal Metrics</b>
      .
    </p>

    <h3 className="section">1. Do Objeto</h3>
    <p>
      A plataforma visa licenciar o uso de seu software, website, aplicativos e demais ativos de
      propriedade intelectual, fornecendo ferramentas para auxiliar e dinamizar o dia a dia dos
      seus usuários.
    </p>
    <p>
      A plataforma caracteriza-se pela prestação dos serviços de “business intelligence” jurídico,
      que pode ser qualificado como um sistema de processamento de bases de dados na forma de
      relatórios e transformação dos mesmos em modelos gráficos por meio da aplicação de
      templates, cópia de dashboards ou criação de novos indicadores.
    </p>
    <p>
      A plataforma é comercializada através do site de sua provedora
      {' '}
      <a
        href="https://juristecplus.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        https://juristecplus.com/
      </a>
      {' '}
      e acessada através do site da URL
      {' '}
      <a
        href="https://legalmetrics.com.br/"
        target="_blank"
        rel="noopener noreferrer"
      >
        https://legalmetrics.com.br/
      </a>
      .
    </p>

    <h3 className="section">2. Da Aceitação</h3>
    <p>
      O presente Termo estabelece obrigações contratadas de livre e espontânea vontade, por tempo
      indeterminado, entre a plataforma e as pessoas físicas ou jurídicas, usuárias do software
      {' '}
      <b>Legal Metrics</b>
      .
    </p>
    <p>
      Ao utilizar a plataforma
      {' '}
      <b>Legal Metrics</b>
      , o usuário aceita integralmente as presentes normas e compromete-se a observá-las.
    </p>
    <p>
      A aceitação do presente instrumento é imprescindível para o acesso e para a utilização de
      quaisquer serviços fornecidos pela empresa. Caso não concorde com as disposições deste
      instrumento, o usuário não deve utilizá-los.
    </p>

    <h3 className="section">3. Do Acesso Dos Usuários</h3>
    <p>
      Serão utilizadas todas as soluções técnicas à disposição do responsável pela plataforma
      para permitir o acesso ao serviço 24 (vinte e quatro) horas por dia, 7 (sete) dias por
      semana. No entanto, a navegação na plataforma ou em alguma de suas páginas poderá ser
      interrompida, limitada ou suspensa para atualizações, modificações, ações necessárias ao
      seu bom funcionamento e/ou por força de terceiros.
    </p>

    <h3 className="section">4. Do Cadastro</h3>
    <p>
      O acesso às funcionalidades da plataforma exigirá a realização de um cadastro prévio e, a
      depender dos serviços ou produtos escolhidos, o pagamento de determinado valor.
    </p>
    <p>
      Ao se cadastrar, o usuário deverá informar dados completos, recentes e válidos, sendo de sua
      exclusiva responsabilidade manter referidos dados atualizados, bem como o usuário se
      compromete com a veracidade dos dados fornecidos.
    </p>
    <p>
      O usuário se compromete a não informar seus dados cadastrais e/ou de acesso à plataforma a
      terceiros, responsabilizando-se integralmente pelo uso que deles seja feito.
    </p>
    <p>
      Menores de 18 anos e aqueles que não possuírem plena capacidade civil deverão obter
      previamente o consentimento expresso de seus responsáveis legais para utilização da
      plataforma e dos serviços ou produtos, sendo de responsabilidade exclusiva dos mesmos o
      eventual acesso por menores de idade e por aqueles que não possuem plena capacidade civil
      sem a prévia autorização.
    </p>
    <p>
      Mediante a realização do cadastro o usuário declara e garante expressamente ser plenamente
      capaz, podendo exercer e usufruir livremente dos serviços e produtos.
    </p>
    <p>
      O usuário deverá fornecer um endereço de e-mail válido, através do qual o site realizará
      todas as comunicações necessárias.
    </p>
    <p>
      Após a confirmação do cadastro, o usuário possuirá um login e uma senha pessoal, a qual
      assegura ao usuário o acesso individual à mesma. Desta forma, compete ao usuário
      exclusivamente a manutenção de referida senha de maneira confidencial e segura, evitando o
      acesso indevido às informações pessoais.
    </p>
    <p>
      Toda e qualquer atividade realizada com o uso da senha será de responsabilidade do usuário,
      que deverá informar prontamente a plataforma em caso de uso indevido da respectiva senha.
    </p>
    <p>
      Não será permitido ceder, vender, alugar ou transferir, de qualquer forma, a conta, que é
      pessoal e intransferível.
    </p>
    <p>
      Caberá ao usuário assegurar que o seu equipamento seja compatível com as características
      técnicas que viabilize a utilização da plataforma e dos serviços ou produtos.
    </p>
    <p>
      O usuário poderá, a qualquer tempo, requerer o cancelamento de seu cadastro junto ao sistema
      {' '}
      <b>Legal Metrics</b>
      . O seu descadastramento será realizado o mais rapidamente possível, desde que
      não sejam verificados débitos em aberto.
    </p>

    <h3 className="section">5. Dos Serviços ou Produtos</h3>
    <p>
      A plataforma poderá dsponibilizar para o usuário um conjunto específico de funcionalidades
      e ferramentas para otimizar o uso dos serviços e produtos.
    </p>
    <p>
      Os serviços ou produtos oferecidos estarão descritos no site da provedora
      {' '}
      <a
        href="https://juristecplus.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        https://juristecplus.com/
      </a>
      {' '}
      e nas documentações competentes, contendo informações sobre suas características, qualidades,
      quantidades, composição, preço, garantia, prazos de validade e origem, entre outros dados,
      bem como sobre os riscos que apresentam à saúde e segurança do usuário.
    </p>
    <p>
      Antes de finalizar a compra sobre determinado produto ou serviço, o usuário deverá se informar
      sobre as suas específicações e sobre a sua destinação.
    </p>

    <h3 className="section">6. Do Chat Integrado</h3>
    <p>
      A depender do pacote contratado, o usuário poderá fazer uso do Chat Integrado da Juristec
      automatizado com inteligência artificial, observadas as seguintes condições:
      <ol type="a">
        <li>
          <b>Natureza da Resposta:</b>
          {' '}
          As respostas fornecidas são geradas automaticamente tendo por base um conjunto de dados
          rodados em rede neural, podendo ser imprecisas, incompletas, desatualizadas e, em casos
          raros, podem ser interpretadas como ofensivas ou inadequadas;
        </li>
        <li>
          <b>Sem Garantias:</b>
          {' '}
          Este serviço contém tecnologia pioneira e é fornecido no estado em que se encontra, não
          sendo garantida sua precisão, relevância ou adequação para qualquer propósito específico.
        </li>
        <li>
          <b>Responsabilidade do Usuário:</b>
          {' '}
          Ao utilizar o chat, é de responsabilidade do usuário usá-lo com discernimento, interpretar
          as respostas com cuidado e consultar fontes adicionais ou profissionais quando necessário.
        </li>
      </ol>
    </p>
    <p>
      Ao utilizar o chat, o usuário declara e concorda que leu, compreendeu e aceitou os termos
      acima, reconhecendo as limitações e capacidades do sistema de rede neural.
    </p>

    <p>
      O usuário, ao aceitar os Termos e Condições Gerais de Uso do Sistema
      {' '}
      <b>Legal Metrics</b>
      {' '}
      e a sua Política de Privacidade, autoriza expressamente a plataforma a coletar, usar,
      armazenar, tratar, ceder ou utilizar as informações derivadas do uso dos serviços, do
      site e suas plataformas, incluindo informações preenchidas pelo usuário no momento em
      que realizar ou atualizar seu cadastro, além de outras, incluindo informações relacionadas
      a dados pessoais nas hipóteses e condições definidas na Política de Privacidade que
      deverá ser autorizada pelo usuário.
    </p>

    <h3 className="section">7. Dos Preços</h3>
    <p>
      Os valores aplicados pelos serviços e produtos são aqueles constantes do contrato firmado
      com a Juristec.
    </p>
    <p>
      Na contratação de determinado serviço ou produto, a plataforma poderá solicitar as
      informações financeiras do usuário, como CPF, endereço de cobrança e dados de cartões. Ao
      inserir referidos dados o usuário concorda que sejam cobrados, de acordo com a forma de
      pagamento que venha a ser escolhida, os preços então vigentes e informados quando da
      contratação. Referidos dados financeiros poderão ser armazenados para facilitar acessos e
      contratações futuras.
    </p>
    <p>
      A contratação dos serviços será renovada automaticamente pela plataforma, independentemente
      de comunicação ao usuário, mediante cobrança periódica da mesma forma de pagamento indicada
      pelo usuário quando da contratação do serviço.
    </p>

    <h3 className="section">7. Do Cancelamento</h3>
    <p>
      O usuário poderá cancelar a contratação dos serviços de acordo com os termos que forem
      definidos no momento de sua contratação. Ainda, o usuário também poderá cancelar os serviços
      em até 7 (sete) dias após a contratação, de acordo com o
      {' '}
      <a
        href="https://www.jusbrasil.com.br/legislacao/91585/c%C3%B3digo-de-defesa-do-consumidor-lei-8078-90"
        target="_blank"
        rel="noopener noreferrer"
      >
        Código de Defesa do Consumidor
      </a>
      {' '}
      (Lei no.
      {' '}
      <a
        href="https://www.jusbrasil.com.br/legislacao/91585/c%C3%B3digo-de-defesa-do-consumidor-lei-8078-90"
        target="_blank"
        rel="noopener noreferrer"
      >
        8.078/90
      </a>
      ).
    </p>

    <h3 className="section">8. Jurisdição Para Resolução de Conflitos</h3>
    <p>
      Para a solução de controvérsias decorrentes do presente instrumento será aplicado
      integralmente o direito brasileiro.
    </p>
    <p>
      Os eventuais litígios deverão ser apresentados no foro da comarca em que se encontra
      a sede da
      {' '}
      <b>Juristec</b>
      .
    </p>
  </>
);

export default UseTermsLM;
